<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="submitForm"
  >
    <div class="pa-5">
      <v-row>
        <v-col>
          <v-select
            label="Select Meter"
            v-model="selectedMeter"
            :items="metersOptions"
            item-value="value"
            @change="handleSelectMeter"
          >
            <template v-slot:selection="{ item }">
              <div class="d-flex">
                <div class="mr-3 d-flex flex-column align-center justify-center">
                  <v-icon :color="item.type === 9 ? 'amber' : 'red'" size="30">mdi-{{item.type === 9 ? 'lightbulb' : 'fire'}}</v-icon>
                </div>
                <div class="ml-3 d-flex flex-column align-center justify-center text-sm">
                  <div v-if="item.type === 9">
                    {{item.pc}} {{item.mtc}} {{item.llf}}
                  </div>
                  <div>
                    {{item.supplyNumber}}
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:item="{ item }">
              <div class="d-flex">
                <div class="mr-3 d-flex flex-column align-center justify-center">
                  <v-icon :color="item.type === 9 ? 'amber' : 'red'" size="30">mdi-{{item.type === 9 ? 'lightbulb' : 'fire'}}</v-icon>
                </div>
                <div class="ml-3 d-flex flex-column align-center justify-center text-sm">
                  <div v-if="item.type === 9">
                    {{item.pc}} {{item.mtc}} {{item.llf}}
                  </div>
                  <div>
                    {{item.supplyNumber}}
                  </div>
                </div>
              </div>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <!-- {{selectedMeter}},
      {{form.loaId}},
      {{form.supplierId}},
      {{userId}},
      {{companyId}},
      {{currentValue}} -->


      <v-row>
        <v-col cols="8">
          <v-select
            v-model="form.loaId"
            :items="filteredLoas"
            @change="handleSelectLoa"
            item-text="text"
            item-value="value"
            label="Select LOA"
            prepend-icon="mdi-office-building-marker"
          >
          </v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            v-model="form.supplierId"
            :items="filteredSuppliers"
            item-text="text"
            item-value="value"
            label="Select Supplier"
            prepend-icon="mdi-office-building-marker"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="mt-4 mb-1">
        <v-btn  color="accent" text  @click.stop="$emit('click-close')">
        Close
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn
          text
          :color="companyColor"
          v-if="selectedMeter && form.loaId && form.supplierId"
          @click="submitTermination"
        >
          <v-row>
            <v-col cols="auto"  style="margin-right: -15px;">
              Send {{ formName }} to
            </v-col>
            <v-col cols="auto">
              <div>
                {{ currentSupplier(form.supplierId).terminationEmail }}              
              </div>
              <br>
              <div>
                {{ currentSupplier(form.supplierId).generalEmail }}              
              </div>
            </v-col>
          </v-row>
          
        </v-btn>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import {changesSaved, somethingWentWrong } from "@/helpers/utilities"
import api from "@/store/api"

export default {
  props: {
    formName: {
      type: String,
      default: ""
    },
    companyColor: {
      type: String,
      default: "grey"
    },
    selectedCompany: {
      type: String
    },
    disabledSubmit: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showSupplyNumberValidationError: false,
    selectedMeter: null,
    valid: true,
    form: {
      meterType: "9",
      addressId: "",
      selectSupplier: "",
      loaId: 0,
      supplierId: 0
    }
  }),
  computed: {
    ...mapGetters("company", ["details", "companyId"]),
    ...mapGetters("addresses", ["selectedAddress"]),
    ...mapGetters("supplier", ["suppliers",  "selectedSupplier"]),
    ...mapGetters("loas", [ "loas"]),
    ...mapGetters("employee", ["userId", "userName", "employeeCampaign"]),
    ...mapGetters("meters", ["meters"]),


    metersOptions() {
      const meters = []
      this.meters.forEach((meter) => {
        if(meter.estimateActual !== "Estimate") {
          meters.push({  
            value: meter.meterId,
            supplyNumber: meter.supplyNumber,
            type: meter.meterTypeId,
            pc: meter.pc,
            mtc: meter.mtc,
            llf: meter.llf
          })
        }
      })
      return meters
    },

    filteredSuppliers() {
          return this.suppliers.map(supplier => ({
            text: supplier.supplierName,
            value: supplier.supplierId
          }))
    },
    
    filteredLoas(){
      if(this.selectedCompany === "utility hive") {
        const filteredLOAArray = this.loas.filter(loa => loa.saleTypeId === 2)
        return filteredLOAArray.map((option) => ({
          text: option.loaText,
          value: option.loaId
        }))
      }
        const filteredLOAArray = this.loas.filter(loa => loa.saleTypeId === 1)
        return filteredLOAArray.map((option) => ({
            text: option.loaText,
            value: option.loaId
          }))
    },
    currentValue() {
      // if(this.selectedMeter){
      //   return this.meters.find(
      //     (option) => option.value === this.selectedMeter
      //   )
      // }
      return this.meters.find(
          (option) => option.meterId === this.selectedMeter
        )
    },
  },
  mounted(){
    this.fetchLoas()
    this.fetchSuppliers()
    // this.fetchMeters()
  },
  methods: {
    ...mapActions("notes", ["fetchNotes"]),
    ...mapActions("loas", ["fetchLoas"]),
    ...mapActions("supplier", ["fetchSuppliers", "selectSupplier"]),
    async fetchMeters(){
      try {
        this.meters = []
        const response = await api.get(`metersByAddressId/${this.selectedAddress.addressId}`)
        if(response.status === 200){
          response.data.forEach((meter) => {
            if(meter.estimateActual !== "Estimate") {
              this.meters.push({
                value: meter.meterId,
                supplyNumber: meter.supplyNumber,
                type: meter.meterTypeId,
                pc: meter.pc,
                mtc: meter.mtc,
                llf: meter.llf
              })
            }
          })
        }
      } catch (error) {
        console.log(error);
      }
    },
    currentSupplier(supplierId) {
      return this.suppliers.find(
        (option) => option.supplierId === supplierId
      )
    },
    handleSelectMeter(){
      console.log('CHANGE', this.selectedMeter);
    },
     handleSelectLoa(){
      console.log('CHANGE', this.form.loaId);
    },
    async submitTermination() {
      try {
        const details = {
          meterTypeId: this.currentValue.meterTypeId,
          loaId: this.form.loaId,
          supplierId: this.form.supplierId,
          supplyNumber: this.currentValue.supplyNumber,
          employeeId: this.userId,
          companyId: this.companyId
        }
        const response = await api.post('sendSupplierTermination', details)
        if(response.status === 200){
          changesSaved('Termination Succesfully Sent')
          this.selectedMeter = null
          this.form.loaId = null
          this.form.supplierId = null
        }
      } catch (error) {
        somethingWentWrong(error)
      }
    }
  },
}
</script>
